import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import RideDataService from "../services/rides";
import EditCustomer from "../components/Admin/EditCustomer";
const CheckRide = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: "",
    ride_code: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [ride, setRide] = useState(null);

  const { email, ride_code } = formData;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: toast.POSITION.TOP_CENTER,
    });

  const handleSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });

  const handleErrors = (errors, inputValues) => {
    // Validation logic
    if (!inputValues.ride_code) {
      errors.ride_code = "Access code is required";
    }

    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "Email address is invalid";
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    errors = handleErrors(errors, formData);
    setFormErrors(errors);

    try {
      const { data } = await RideDataService.getFromCode(formData);
      //console.log("data", data);
      const { success, message, ride: ride_s } = data;
      if (success) {
        setRide(ride_s);
        handleSuccess(message);
      } else {
        handleError(message);
      }
    } catch (error) {
      // Check if the error response contains specific information
      if (error.response && error.response.data && error.response.data.error) {
        const m_error = error.response.data.error;
        if (m_error.includes("Ride not found")) {
          handleError(t("CodePage.verify_data")); // Custom message for "Ride not found"
        } else {
          handleError(m_error); // Handle other errors returned from the server
        }
      } else {
        handleError(t("CodePage.server_error")); // Fallback error message
      }

      console.log(error);
    }
  };

  return (
    <div className="w-full py-16 px-4">
      {!ride && (
        <div className="max-w-[1240px] sm:mx-auto sm:w-full sm:max-w-md">
          <div>
            <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
              {t("CodePage.message")}
            </h2>
            <p className="mt-2 text-sm leading-6 text-gray-400">
              {t("CodePage.sub_message")}{" "}
            </p>
          </div>
          <div className="mt-10">
            <div>
              <form onSubmit={handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    {t("CodePage.email")}
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={email}
                      onChange={handleOnChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formErrors.email && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formErrors.email}
                      </p>
                    )}
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="code"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    {t("CodePage.code")}
                  </label>
                  <div className="mt-2">
                    <input
                      id="ride_code"
                      name="ride_code"
                      type="text"
                      value={ride_code}
                      onChange={handleOnChange}
                      required
                      className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {formErrors.ride_code && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formErrors.ride_code}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <button
                    type="submit  "
                    className="flex w-full justify-center rounded-md bg-[#00df9a] px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    {t("CodePage.button")}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {ride && (
        <div className="max-w-[1240px] py-16">
          <div className="bg-gray-800 pb-32">
            <header className="py-10">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <h1 className="text-3xl font-bold tracking-tight text-[#00df9a]">
                  {t("EditPage.link")}
                </h1>
              </div>
            </header>
          </div>

          <div className="bg-gray-800 -mt-32">
            <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
              <div className="rounded-lg bg-black px-5 py-6 shadow sm:px-6">
                <EditCustomer ride={ride} code={ride.ride_code} />
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default CheckRide;
