import React, { useState } from "react";
import Datepicker from "react-tailwindcss-datepicker";
import countries from "../utils/countries";
import { Link, useNavigate } from "react-router-dom";
import Destination from "./Destination";
import Departure from "./Departure";
import FlightInfo from "./FlightInfo";
import { BiSolidPlaneTakeOff, BiSolidPlaneLand } from "react-icons/bi";
import { getFormattedDate } from "../utils/dateUtils";
import {
  bel_loc_values,
  fra_cities_values,
  ger_cities_values,
  lux_cities_values,
  net_cities_values,
  zvt_bruPriceMapping,
  zvt_fraPriceMapping,
  zvt_gerPriceMapping,
  zvt_luxPriceMapping,
  zvt_netPriceMapping,
  crl_bruPriceMapping,
  crl_fraPriceMapping,
  crl_gerPriceMapping,
  crl_luxPriceMapping,
  crl_netPriceMapping,
} from "../utils/pricing_t2b";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";
import RideDataService from "../../services/rides";
import config from '../../config';

const BookRide = ({ mode = "departure", passengers = 1, airport = "" }) => {
  const primaryColor = config.env.primaryColor || "#00df9a";
  const { t } = useTranslation();
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const successToast = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const errorToast = (message) => {
    toast.warning(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  //const warningToast = (message) => {
  //  toast.warning(message, {
  //     position: toast.POSITION.TOP_CENTER
  // });
  // }

  let airport_desti = "";
  let airport_depar = "";
  let fligh;
  let f_origin;
  let f_number;
  let f_time;

  if (mode === "departure" && airport !== "") {
    airport_depar = airport;
    fligh = "";
    f_origin = "";
    f_number = "";
    f_time = "";
    airport_desti = "NA";
  } else if (mode === "destination" && airport !== "") {
    airport_desti = airport;
    airport_depar = "NA";
    fligh = "NA";
    f_origin = "NA";
    f_number = "NA";
    f_time = "";
  }

  const [formData, setFormData] = useState({
    price: 0,
    passengers: passengers,
    destination: airport_desti,
    departure: airport_depar,
    country: "",
    country_loc: "",
    bel_addr: "",
    price_validation: "",
    date: getFormattedDate(),
    time: "",
    carseats: 0,
    boosterseats: 0,
    wheelchairs: "0",
    fligh_desti: fligh,
    fligh_origin: f_origin,
    fligh_number: f_number,
    arrival_time: f_time,
    firstname: "",
    lastname: "",
    email: "",
    countrycode: "",
    phone: "",
    details: "",
    payment_mode: "cash",
  });

  const [datePicker, setDatePicker] = useState({
    startDate: new Date(),
    endDate: new Date(),
  });

  const handleDatePickerValueChange = (newValue) => {
    setDatePicker(newValue);
    setFormData({
      ...formData,
      date: newValue.startDate,
    });
  };

  const setPrice = (price, updatedFormData) => {
    const pass = parseInt(updatedFormData.passengers);
    const multiply = parseInt(pass / 8) + 1;
    setFormData({
      ...updatedFormData,
      price: price * multiply,
      price_validation: "",
    });
  };

  const handlePriceUpdate = (e, updatedFormData) => {
    const { name, value } = e.target;

    if (name === "country_loc") {
      if (updatedFormData.country === "bel" && bel_loc_values.includes(value)) {
        if (
          updatedFormData.destination === "bru" ||
          updatedFormData.departure === "bru"
        ) {
          const newPrice = zvt_bruPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else if (
          updatedFormData.destination === "crl" ||
          updatedFormData.departure === "crl"
        ) {
          const newPrice = crl_bruPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else {
          setPrice(0, updatedFormData);
        }
      } else if (
        updatedFormData.country === "fra" &&
        fra_cities_values.includes(value)
      ) {
        if (
          updatedFormData.destination === "bru" ||
          updatedFormData.departure === "bru"
        ) {
          const newPrice = zvt_fraPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else if (
          updatedFormData.destination === "crl" ||
          updatedFormData.departure === "crl"
        ) {
          const newPrice = crl_fraPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else {
          setPrice(0, updatedFormData);
        }
      } else if (
        updatedFormData.country === "lux" &&
        lux_cities_values.includes(value)
      ) {
        if (
          updatedFormData.destination === "bru" ||
          updatedFormData.departure === "bru"
        ) {
          const newPrice = zvt_luxPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else if (
          updatedFormData.destination === "crl" ||
          updatedFormData.departure === "crl"
        ) {
          const newPrice = crl_luxPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else {
          setPrice(0, updatedFormData);
        }
      } else if (
        updatedFormData.country === "net" &&
        net_cities_values.includes(value)
      ) {
        if (
          updatedFormData.destination === "bru" ||
          updatedFormData.departure === "bru"
        ) {
          const newPrice = zvt_netPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else if (
          updatedFormData.destination === "crl" ||
          updatedFormData.departure === "crl"
        ) {
          const newPrice = crl_netPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else {
          setPrice(0, updatedFormData);
        }
      } else if (
        updatedFormData.country === "ger" &&
        ger_cities_values.includes(value)
      ) {
        if (
          updatedFormData.destination === "bru" ||
          updatedFormData.departure === "bru"
        ) {
          const newPrice = zvt_gerPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else if (
          updatedFormData.destination === "crl" ||
          updatedFormData.departure === "crl"
        ) {
          const newPrice = crl_gerPriceMapping[value];
          if (newPrice !== undefined) {
            setPrice(newPrice, updatedFormData);
          } else {
            setPrice(0, updatedFormData);
          }
        } else {
          setPrice(0, updatedFormData);
        }
      } else {
        setPrice(0, updatedFormData);
      }
    } else {
      setPrice(0, updatedFormData);
    }
  };

  const handleChangeDestination = (e) => {
    const { name, value } = e.target;
    if (name === "destination" || name === "departure") {
      setFormData({
        ...formData,
        [name]: value,
        country: "",
        country_loc: "",
        bel_addr: "",
        price: 0,
        price_validation: "",
      });
    }
  };

  const resetValues = (e) => {
    setFormData({
      ...formData,
      country: "",
      country_loc: "",
      bel_addr: "",
      price: 0,
      price_validation: "",
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;
    const priceEvent = ["country_loc"];
    //use a Promise to handle the asynchronous nature of state updates in React, wrap the setFormData and handlePriceUpdate calls in a Promise.
    //The use of async/await and Promise ensures that the state update completes before moving on to the next operation.
    try {
      const updatedFormData_1 = await new Promise((resolve) => {
        setFormData((formData) => {
          const updatedFormData = {
            ...formData,
            [name]: value,
          };
          resolve(updatedFormData);
          return updatedFormData;
        });
      });
      if (priceEvent.includes(name)) {
        handlePriceUpdate(e, updatedFormData_1);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  const handlerrors = (errors, inputValues) => {
    // Validation logic
    if (!inputValues.passengers) {
      errors.passengers = "Passenger number is required";
    } else if (parseInt(inputValues.passengers) < 1) {
      errors.passengers = "Error with the passenger number";
    }

    if (!inputValues.destination) {
      if (!inputValues.departure) {
        errors.destination = "Destination is required";
      }
    } else if (
      inputValues.destination !== "bru" &&
      inputValues.destination !== "crl" &&
      inputValues.destination !== "NA"
    ) {
      errors.destination = "Destination is invalid";
    }

    if (!inputValues.date) {
      errors.time = "Depart date is required";
    }

    if (inputValues.destination && !inputValues.time) {
      errors.time = "Depart time is required";
    }

    if (!inputValues.departure) {
      if (!inputValues.destination) {
        errors.departure = "Destination is required";
      }
    } else if (
      inputValues.departure !== "bru" &&
      inputValues.departure !== "crl" &&
      inputValues.departure !== "NA"
    ) {
      errors.departure = "Departure is invalid";
    }

    if (inputValues.departure !== "NA" && inputValues.departure !== "") {
      if (!inputValues.fligh_origin) {
        errors.fligh_origin = "Depart Origin is required";
      }
      if (!inputValues.fligh_number) {
        errors.fligh_number = "Flight number is required";
      }
      if (!inputValues.arrival_time) {
        errors.arrival_time = "Arrival time is required";
      }
    }

    if (!inputValues.country) {
      errors.country = "Country is required";
    }

    if (!inputValues.price && inputValues.bel_addr === "") {
      errors.price = "The price has been not defined";
    } else if (
      parseInt(inputValues.price) < 42 &&
      inputValues.bel_addr === ""
    ) {
      errors.price = "Error with the pricing";
    }

    if (!inputValues.date) {
      errors.date = "Date is required";
    }

    if (!inputValues.firstname) {
      errors.firstname = "Firstname is required";
    }

    if (!inputValues.lastname) {
      errors.lastname = "Lastname is required";
    }

    if (!inputValues.email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
      errors.email = "Email address is invalid";
    }

    if (!inputValues.countrycode) {
      errors.countrycode = "Country code is required";
    }

    if (!inputValues.phone) {
      errors.phone = "Phone code is required";
    }

    if (!inputValues.payment_mode) {
      errors.payment_mode = "Payment mode is required";
    }
    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true); // Disable the button

    let errors = {};
    errors = handlerrors(errors, formData);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      //const submitData = new FormData(event.target);
      //submitData.forEach((value, key) => {
      //console.log(`Field Name: ${key}, Value: ${value}`);
      //});

      if (mode === "departure") {
        formData.destination = "NA";
        formData.fligh_desti = formData.departure;
        formData.time = formData.arrival_time;
      } else if (mode === "destination") {
        formData.departure = "NA";
        formData.fligh_desti = "";
        formData.fligh_origin = "";
        formData.fligh_number = "";
        formData.arrival_time = "";
      }

      if (formData.bel_addr === "") {
        formData.bel_addr = formData.country_loc;
      }

      //axiooooooooooooooooooooooooooooooos
      console.log("Before sending formData");
      console.log(formData);
      try {
        const { data } = await RideDataService.createRide(formData);
        const { success, message } = data;
        if (success) {
          successToast(message);
          console.log("success:", message);
          setTimeout(() => {
            navigate("/thankyou");
          }, 3000);
        } else {
          errorToast("The server could not manage the request.");
          console.log("Not success:", message);
        }
      } catch (error) {
        setIsSubmitting(false); // Re-enable the button
        errorToast("We can not contact the server.");
        console.log(error);
      }
      event.target.reset(); // Reset the form
    } else {
      setIsSubmitting(false); // Re-enable the button
      errorToast("There are erros in the form.");
      console.log(errors);
    }
    //setIsSubmitting(false); // Re-enable the button
  };

  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4">
      <div className="text-white mb-7">
        {mode === "destination" && (
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("MainForm.title_to")} --
            <span className="inline-flex">
              <BiSolidPlaneTakeOff size={40} />
            </span>
          </h1>
        )}
        {mode === "departure" && (
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">
            {t("MainForm.title_from")} --
            <span className="inline-flex">
              <BiSolidPlaneLand size={40} />
            </span>
          </h1>
        )}
      </div>
      <div className="text-white mb-7">
        {mode === "departure" && (
          <Link to={`/booking/destination`} onClick={resetValues}>
            <button className="hover:bg-indigo-400 w-[325px] rounded-md font-medium my-6 mx-auto py-3 text-black justify-center inline-flex" style={{ backgroundColor: primaryColor }}>
              {" "}
              <BiSolidPlaneTakeOff size={20} />
              --{t("Button.clic_to")}
            </button>
          </Link>
        )}
        {mode === "destination" && (
          <Link to={`/booking/departure`} onClick={resetValues}>
            <button className="hover:bg-indigo-400 w-[325px] rounded-md font-medium my-6 mx-auto py-3 text-black inline-flex justify-center" style={{ backgroundColor: primaryColor }}>
              <BiSolidPlaneLand size={20} />
              -- {t("Button.clic_from")}
            </button>
          </Link>
        )}
      </div>
      <form onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
        <div className="space-y-12">
          <div className="border-b border-white/10 pb-12">
            <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
              {t("MainForm.pricing")}
            </h2>
            <p className="mt-1 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
              {t("MainForm.pric_sub")}
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {mode === "destination" && (
                <Destination
                  formData={formData}
                  formErrors={formErrors}
                  handleChange={handleChange}
                  handleChangeDestination={handleChangeDestination}
                />
              )}
              {mode === "departure" && (
                <Departure
                  formData={formData}
                  formErrors={formErrors}
                  handleChange={handleChange}
                  handleChangeDestination={handleChangeDestination}
                />
              )}
              {(formData.price !== 0 || formData.bel_addr !== "") && (
                <div className="border-t border-white/10 pb-4 sm:col-span-4">
                  <div className="mt-10 space-y-10">
                    <fieldset>
                      <legend className="md:text-2xl sm:text-xl text-sm font-semibold leading-6 text-white">
                        {formData.price !== 0 && (
                          <>
                            {t("MainForm.price_is")}{" "}
                            <span className="md:text-3xl sm:text-2xl text-xl" style={{ color: primaryColor }}>
                              {formData.price *
                                (parseInt(formData.passengers / 8.1) + 1)}
                              $
                            </span>
                          </>
                        )}{" "}
                        {formData.price === 0 && (
                          <span className="md:text-3xl sm:text-2xl text-xl" style={{ color: primaryColor }}>
                            {t("MainForm.price_limit")}
                          </span>
                        )}{" "}
                      </legend>
                      <p className="mt-1 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
                        {t("MainForm.price_val")}
                      </p>
                      <div className="mt-6 space-y-6">
                        <div className="flex items-center gap-x-3">
                          <input
                            onChange={handleChange}
                            checked={
                              formData.price_validation === "yes" && true
                            }
                            id="price_yes"
                            name="price_validation"
                            value="yes"
                            type="radio"
                            className="h-4 w-4 border-white/10 bg-white/5 text-green-600 focus:ring-green-600 focus:ring-offset-gray-900"
                          />
                          <label
                            htmlFor="price_yes"
                            className="block  sm:text-2xl text-xl font-medium leading-6 text-white"
                          >
                            {t("MainForm.pri_yes")}.
                          </label>
                        </div>
                        <div className="flex items-center gap-x-3">
                          <input
                            onChange={handleChange}
                            checked={formData.price_validation === "no" && true}
                            id="price_no"
                            name="price_validation"
                            value="no"
                            type="radio"
                            className="h-4 w-4 border-white/10 bg-white/5 text-red-600 focus:ring-red-600 focus:ring-offset-gray-900"
                          />
                          <label
                            htmlFor="price_no"
                            className="block  sm:text-xl text-base font-medium leading-6 text-white"
                          >
                            {t("MainForm.pric_no")}.
                          </label>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              )}

              {formData.price_validation === "yes" && (
                <>
                  <div className="border-t border-white/10 sm:col-span-6">
                    <p className="mt-10 mb-5 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
                      {t("MainForm.info_taxi")}
                    </p>
                  </div>
                  <div className="sm:col-span-4 dark">
                    <label
                      htmlFor="username"
                      className="mb-2 block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                    >
                      {t("MainForm.date")}
                    </label>
                    <Datepicker
                      value={datePicker}
                      onChange={handleDatePickerValueChange}
                      primaryColor={"green"}
                      className="dark:bg-gray-800 dark:text-white"
                      asSingle={true}
                      useRange={false}
                      minDate={new Date()}
                      maxDate={new Date().setMonth(new Date().getMonth() + 1)}
                    />
                    {formErrors.date && (
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {formErrors.date}
                      </p>
                    )}
                  </div>
                  {mode === "destination" && (
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="username"
                        className="mb-2 block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                      >
                        {t("MainForm.time")}
                      </label>
                      <div className="mt-2">
                        <div className="flex rounded-md bg-white/5 ring-1 ring-inset ring-white/10 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                          <input
                            type="time"
                            name="time"
                            value={formData.time}
                            onChange={handleChange}
                            id="time"
                            required
                            className="flex-1 border-0 bg-transparent pl-1 text-white focus:ring-0 sm:text-base sm:leading-6"
                          />
                          {formErrors.time && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="time-error"
                            >
                              {formErrors.time}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="carseats"
                      className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                    >
                      {t("MainForm.car_seats")}
                    </label>
                    <div className="mt-1">
                      <select
                        id="carseats"
                        name="carseats"
                        value={formData.carseats}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                      {formErrors.carseats && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="carseats-error"
                        >
                          {formErrors.carseats}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="boosterseats"
                      className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                    >
                      {t("MainForm.boost_seats")}
                    </label>
                    <div className="mt-1">
                      <select
                        id="boosterseats"
                        name="boosterseats"
                        value={formData.boosterseats}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                      </select>
                      {formErrors.boosterseats && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="boosterseats-error"
                        >
                          {formErrors.boosterseats}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="wheelchairs"
                      className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                    >
                      {t("MainForm.wc")}
                    </label>
                    <div className="mt-1">
                      <select
                        id="wheelchairs"
                        name="wheelchairs"
                        value={formData.wheelchairs}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                      >
                        <option value="0">0</option>
                        <option value="1c">1 {t("MainForm.wc_co")}</option>
                        <option value="2c">2 {t("MainForm.wc_co")}</option>
                        <option value="1nc">1 {t("MainForm.wc_no_co")}</option>
                        <option value="2nc">2 {t("MainForm.wc_no_co")}</option>
                        <option value="1c1nc">
                          1 {t("MainForm.wc_co")} + 1 {t("MainForm.wc_no_co")}
                        </option>
                      </select>
                      {formErrors.wheelchairs && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="wheelchairs-error"
                        >
                          {formErrors.wheelchairs}
                        </p>
                      )}
                    </div>
                  </div>

                  {mode === "departure" && (
                    <FlightInfo
                      formData={formData}
                      formErrors={formErrors}
                      handleChange={handleChange}
                    />
                  )}

                  <div className="sm:col-span-4 border-b pt-4 border-white/100 pb-12">
                    <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
                      {t("MainForm.perso_inf")}
                    </h2>
                    <p className="mt-1 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
                      {t("MainForm.perso_sub")}
                    </p>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="firstname"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.firstname")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="firstname"
                            id="firstname"
                            value={formData.firstname}
                            onChange={handleChange}
                            required
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                          />
                          {formErrors.firstname && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="firstname-error"
                            >
                              {formErrors.firstname}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="lastname"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.lastname")}
                        </label>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="lastname"
                            id="lastname"
                            value={formData.lastname}
                            onChange={handleChange}
                            required
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                          />
                          {formErrors.lastname && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="lastname-error"
                            >
                              {formErrors.lastname}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="email"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.email")}
                        </label>
                        <div className="mt-2">
                          <input
                            id="email"
                            name="email"
                            type="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                            autoComplete="email"
                            className={`block w-full rounded-md  bg-white/5 py-1.5 shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset  sm:text-base sm:leading-6 ${
                              formErrors.email
                                ? "border-pink-500 text-pink-600 border-1"
                                : "text-white border-0"
                            }`}
                          />
                          {formErrors.email && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="email-error"
                            >
                              {formErrors.email}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label
                          htmlFor="countrycode"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.country_code")}
                        </label>
                        <div className="mt-2">
                          <select
                            id="countrycode"
                            name="countrycode"
                            value={formData.countrycode}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                          >
                            <option value="" disabled>
                              {t("MainForm.country_code_select")}
                            </option>
                            {countries.map(([country, _, __, code], index) => (
                              <option key={index} value={code}>
                                {`${country} (+${code})`}
                              </option>
                            ))}
                          </select>
                          {formErrors.countrycode && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="countrycode-error"
                            >
                              {formErrors.countrycode}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label
                          htmlFor="phone"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.phone")}
                        </label>
                        <div className="mt-2">
                          <input
                            id="phone"
                            name="phone"
                            type="tel"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                            autoComplete="phone"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                          />
                          {formErrors.phone && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="phone-error"
                            >
                              {formErrors.phone}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="col-span-full">
                        <label
                          htmlFor="details"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.more")}
                        </label>
                        <div className="mt-2">
                          <textarea
                            id="details"
                            name="details"
                            value={formData.details}
                            onChange={handleChange}
                            rows="3"
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sm:col-span-4  pt-1  pb-1">
                    <h2 className="md:text-3xl sm:text-2xl text-xl font-semibold leading-7 text-white">
                      {t("MainForm.payment")}
                    </h2>

                    <div className="mt-5 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-6">
                        <label
                          htmlFor="payment_mode"
                          className="block md:text-2xl sm:text-xl text-sm font-medium leading-6 text-white"
                        >
                          {t("MainForm.mode")}
                        </label>
                        <div className="mt-1">
                          <select
                            id="payment_mode"
                            name="payment_mode"
                            value={formData.payment_mode}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-base sm:leading-6 [&_*]:text-black"
                          >
                            <option value="cash">{t("MainForm.mode_1")}</option>
                            <option value="card">{t("MainForm.mode_2")}</option>
                          </select>
                          {formErrors.payment_mode && (
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="payment_mode-error"
                            >
                              {formErrors.payment_mode}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        {formData.price_validation === "yes" && (
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <p className="sm:col-span-6 md:text-2xl sm:text-xl text-sm leading-6 text-gray-400">
              {t("MainForm.terms")}{" "}
              <Link
                to="/terms"
                target="_blank"
                rel="noopener noreferrer"
                className=""
                style={{ color: primaryColor }}
              >
                {t("MainForm.terms_link")}
              </Link>
            </p>
            <div className="sm:col-span-6 mt-6 flex items-center justify-end gap-x-6">
              <button
                type="submit"
                className="w-full rounded-md active:bg-indigo-700 px-16 py-2 md:text-2xl sm:text-xl text-sm font-semibold text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500 disabled:text-slate-500"
                style={{ backgroundColor: primaryColor }}
                disabled={isSubmitting} // Disable the button while submitting
              >
                {t("Button.book")}{" "}
              </button>
            </div>
            {Object.keys(formErrors).length > 0 && (
              <p
                className="sm:col-span-6 md:text-xl sm:text-base text-sm leading-6 text-red-600"
                id="message-error"
              >
                {t("MainForm.errors")}
              </p>
            )}
          </div>
        )}
      </form>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default BookRide;
