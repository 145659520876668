import React from "react";
import {
  FaFacebookSquare,
  FaInstagram,
  FaTwitterSquare,
  FaHeartbeat,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "../config";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className="w-full py-16 text-white px-4">
      <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300">
        <div>
          <h1 className="w-full text-3xl font-bold text-[#00df9a]">
            {config.appName}
          </h1>
          <p className="py-4">{t("Footer.description")}</p>
          <div className="flex justify-between md:w-[75%] my-6">
            <FaFacebookSquare size={30} />
            <FaInstagram size={30} />
            <FaTwitterSquare size={30} />
          </div>
        </div>
        <div className="lg:col-span-2 flex justify-between mt-6">
          <div>
            <h6 className="font-medium text-gray-400">{t("Footer.title_1")}</h6>
            <ul>
              <li className="cursor-pointer py-2 text-sm">
                {t("Footer.el_11")}
              </li>
              <li className="cursor-pointer py-2 text-sm">
                {t("Footer.el_12")}
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">{t("Footer.title_2")}</h6>
            <ul>
              <li className="py-2 text-sm">
                <Link to="/booking">{t("Footer.el_21")}</Link>
              </li>
              <li className="cursor-pointer py-2 text-sm">
                {t("Footer.el_22")}
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">{t("Footer.title_3")}</h6>
            <ul>
              <li className="cursor-pointer py-2 text-sm">
                {t("Footer.el_31")}
              </li>
            </ul>
          </div>
          <div>
            <h6 className="font-medium text-gray-400">{t("Footer.title_4")}</h6>
            <ul>
              <li className="cursor-pointer py-2 text-sm">
                <Link to="/privacy">{t("Footer.el_41")}</Link>
              </li>
              <li className="cursor-pointer py-2 text-sm">
                <Link to="/terms">{t("Footer.el_42")}</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="max-w-[1240px] mx-auto text-center">
        <p>
          Crafted with &nbsp;{" "}
          <span className="inline-flex">
            <FaHeartbeat size={20} color={"#00df9a"} />
          </span>
          &nbsp; by a skilled team of builders @ &nbsp;
          <a
            className="text-[#00df9a] hover:text-indigo-400"
            href="https://justonline.be"
            target="_blank"
            rel="noreferrer"
          >
            Justonline.be
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
