import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UserContext from "../contexts/UserContext";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login } = useContext(UserContext);
  const [inputValue, setInputValue] = useState({
    email: "",
    password: "",
  });
  const { authenticated } = useContext(UserContext);
  if (authenticated) {
    return <Navigate to="/dashboard" replace />;
  }

  const { email, password } = inputValue;

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  const handleError = (err) =>
    toast.error(err, {
      position: toast.POSITION.TOP_CENTER,
    });
  /*const handleSuccess = (msg) =>
    toast.success(msg, {
      position: toast.POSITION.TOP_CENTER,
    });*/

  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(inputValue);
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/auth/login?api_key=${process.env.REACT_APP_API_KEY}`,
        null,
        {
          headers: {
            Authorization:
              "Basic " + btoa(inputValue.email + ":" + inputValue.password),
          },
        }
      );
      //console.log("data", data);
      const { success, message, token, user } = data;
      if (success) {
        //handleSuccess(message);
        login(user, token); // Update the global state with user info
        navigate("/dashboard", {
          state: { message },
        });
      } else {
        handleError(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-full py-16 px-4">
      <div className="max-w-[1240px] sm:mx-auto sm:w-full sm:max-w-md">
        <div>
          <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-white">
            {t("LoginPage.message")}
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-400">
            {t("LoginPage.sub_message")}{" "}
          </p>
        </div>
        <div className="mt-10">
          <div>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("LoginPage.email")}
                </label>
                <div className="mt-2">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onChange={handleOnChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-white"
                >
                  {t("LoginPage.password")}
                </label>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    value={password}
                    onChange={handleOnChange}
                    required
                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-3 block text-sm leading-6 text-white"
                  >
                    {t("LoginPage.remember_me")}
                  </label>
                </div>

                <div className="text-sm leading-6">
                  <a
                    href="/"
                    className="font-semibold text-[#00df9a] hover:text-indigo-300"
                  >
                    {t("LoginPage.forgot_password")}
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-[#00df9a] px-3 py-1.5 text-sm font-semibold leading-6 text-black shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {t("LoginPage.button")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" theme="dark" />
    </div>
  );
};

export default Login;
